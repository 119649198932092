// Remove all test users, only keep admin credentials for testing
export const adminCredentials = {
  admin: {
    email: 'admin@ynotpost.com',
    password: 'admin123'
  },
  moderator: {
    email: 'moderator@ynotpost.com',
    password: 'mod123'
  }
};
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { storage } from '../lib/storage';
import { syncManager } from '../lib/syncManager';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

export interface Listing {
  id: string;
  userId: string;
  title: string;
  price: number;
  description: string;
  images: string[];
  location: string;
  category: string;
  rating: number;
  reviews: number;
  views: number;
  comments: number;
  createdAt: string;
  sold?: boolean;
  buyerId?: string;
  recentOffer?: {
    amount: number;
    by: 'buyer' | 'seller';
    timestamp: string;
  };
  seller: {
    id: string;
    name: string;
    rating: number;
    reviews: number;
    avatar: string;
  };
}

interface ListingStore {
  listings: Listing[];
  version: number;
  initializeListings: () => Promise<void>;
  addListing: (listing: Omit<Listing, 'id' | 'createdAt'>) => Promise<void>;
  updateListing: (id: string, updates: Partial<Listing>) => Promise<void>;
  removeListing: (id: string) => Promise<void>;
  markAsSold: (id: string, buyerId: string) => Promise<void>;
  addOffer: (listingId: string, amount: number, buyerId: string) => Promise<void>;
  getListingsByCategory: (category: string) => Listing[];
  getListingsByUser: (userId: string) => Listing[];
  getSoldListingsByUser: (userId: string) => Listing[];
  getActiveListingsByUser: (userId: string) => Listing[];
  getPurchasesByUser: (userId: string) => Listing[];
  getAllListings: () => Listing[];
}

export const useListingStore = create<ListingStore>()(
  persist(
    (set, get) => ({
      listings: [],
      version: 1,

      initializeListings: async () => {
        try {
          const { data: listings, error } = await supabase
            .from('listings')
            .select(`
              *,
              user:user_id (
                id,
                name,
                avatar_url,
                rating,
                reviews
              )
            `)
            .eq('status', 'active')
            .order('created_at', { ascending: false });

          if (error) throw error;

          const formattedListings = listings?.map(listing => ({
            ...listing,
            seller: {
              id: listing.user.id,
              name: listing.user.name,
              avatar: listing.user.avatar_url,
              rating: listing.user.rating,
              reviews: listing.user.reviews
            }
          })) || [];

          set({ listings: formattedListings });
          storage.set('listings', formattedListings);
        } catch (error) {
          console.error('Error initializing listings:', error);
          // Fallback to local storage if Supabase fails
          const cachedListings = storage.get<Listing[]>('listings') || [];
          set({ listings: cachedListings });
        }
      },

      getAllListings: () => {
        return get().listings.filter(listing => !listing.sold);
      },
      
      addListing: async (listing) => {
        try {
          const { data, error } = await supabase
            .from('listings')
            .insert([{
              ...listing,
              id: uuidv4(),
              created_at: new Date().toISOString(),
              status: 'active'
            }])
            .select()
            .single();

          if (error) throw error;

          set(state => ({
            listings: [data, ...state.listings]
          }));

          toast.success('Listing created successfully');
        } catch (error) {
          console.error('Error adding listing:', error);
          toast.error('Failed to create listing');
          throw error;
        }
      },

      updateListing: async (id, updates) => {
        try {
          const { error } = await supabase
            .from('listings')
            .update(updates)
            .eq('id', id);

          if (error) throw error;

          set(state => ({
            listings: state.listings.map(listing =>
              listing.id === id ? { ...listing, ...updates } : listing
            )
          }));

          toast.success('Listing updated successfully');
        } catch (error) {
          console.error('Error updating listing:', error);
          toast.error('Failed to update listing');
          throw error;
        }
      },

      removeListing: async (id) => {
        try {
          const { error } = await supabase
            .from('listings')
            .update({ status: 'deleted' })
            .eq('id', id);

          if (error) throw error;

          set(state => ({
            listings: state.listings.filter(listing => listing.id !== id)
          }));

          toast.success('Listing removed successfully');
        } catch (error) {
          console.error('Error removing listing:', error);
          toast.error('Failed to remove listing');
          throw error;
        }
      },

      markAsSold: async (id, buyerId) => {
        try {
          const { error } = await supabase
            .from('listings')
            .update({
              status: 'sold',
              buyer_id: buyerId,
              sold_at: new Date().toISOString()
            })
            .eq('id', id);

          if (error) throw error;

          set(state => ({
            listings: state.listings.map(listing =>
              listing.id === id ? { ...listing, sold: true, buyerId } : listing
            )
          }));

          toast.success('Listing marked as sold');
        } catch (error) {
          console.error('Error marking listing as sold:', error);
          toast.error('Failed to mark listing as sold');
          throw error;
        }
      },

      getListingsByCategory: (category) => {
        return get().listings.filter(listing => 
          listing.category === category && !listing.sold
        );
      },

      getListingsByUser: (userId) => {
        return get().listings.filter(listing => listing.userId === userId);
      },

      getSoldListingsByUser: (userId) => {
        return get().listings.filter(listing => 
          listing.userId === userId && listing.sold === true
        );
      },

      getActiveListingsByUser: (userId) => {
        return get().listings.filter(listing => 
          listing.userId === userId && !listing.sold
        );
      },

      getPurchasesByUser: (userId) => {
        return get().listings.filter(listing => listing.buyerId === userId);
      },

      addOffer: async (listingId, amount, buyerId) => {
        try {
          const { error } = await supabase
            .from('offers')
            .insert({
              listing_id: listingId,
              buyer_id: buyerId,
              amount,
              status: 'pending'
            });

          if (error) throw error;

          set(state => ({
            listings: state.listings.map(listing =>
              listing.id === listingId
                ? {
                    ...listing,
                    recentOffer: {
                      amount,
                      by: 'buyer',
                      timestamp: new Date().toISOString()
                    }
                  }
                : listing
            )
          }));

          toast.success('Offer sent successfully');
        } catch (error) {
          console.error('Error adding offer:', error);
          toast.error('Failed to send offer');
          throw error;
        }
      }
    }),
    {
      name: 'listings-storage',
      version: 1,
      onRehydrateStorage: () => {
        return (state) => {
          if (state) {
            state.initializeListings();
          }
        };
      }
    }
  )
);

// Initialize listings when the store is created
useListingStore.getState().initializeListings();
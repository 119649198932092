import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';
import { adminCredentials } from '../utils/testUsers';
import toast from 'react-hot-toast';

interface User {
  id: string;
  name: string;
  email: string;
  role: 'user' | 'admin' | 'moderator';
  avatar?: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  adminSignIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      isAdmin: false,

      signUp: async (email: string, password: string, name: string) => {
        try {
          const { data, error } = await supabase.auth.signUp({
            email,
            password,
            options: {
              data: { name }
            }
          });

          if (error) throw error;

          if (data.user) {
            const user = {
              id: data.user.id,
              name,
              email: data.user.email || '',
              role: 'user'
            };
            set({ user, isAuthenticated: true, isAdmin: false });
            toast.success('Account created successfully!');
          }
        } catch (error) {
          console.error('Sign up error:', error);
          toast.error(error instanceof Error ? error.message : 'Failed to create account');
          throw error;
        }
      },

      signIn: async (email: string, password: string) => {
        try {
          const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password
          });

          if (error) throw error;

          if (data.user) {
            const { data: profile } = await supabase
              .from('profiles')
              .select('*')
              .eq('id', data.user.id)
              .single();

            const user = {
              id: data.user.id,
              name: profile?.name || data.user.email?.split('@')[0] || 'User',
              email: data.user.email || '',
              role: 'user',
              avatar: profile?.avatar_url
            };
            set({ user, isAuthenticated: true, isAdmin: false });
            toast.success('Signed in successfully!');
          }
        } catch (error) {
          console.error('Sign in error:', error);
          toast.error(error instanceof Error ? error.message : 'Failed to sign in');
          throw error;
        }
      },

      adminSignIn: async (email: string, password: string) => {
        try {
          if (email === adminCredentials.admin.email && password === adminCredentials.admin.password) {
            const adminUser = {
              id: 'admin',
              name: 'Admin',
              email,
              role: 'admin'
            };
            set({ user: adminUser, isAuthenticated: true, isAdmin: true });
            toast.success('Welcome, Admin!');
          } else if (email === adminCredentials.moderator.email && password === adminCredentials.moderator.password) {
            const modUser = {
              id: 'moderator',
              name: 'Moderator',
              email,
              role: 'moderator'
            };
            set({ user: modUser, isAuthenticated: true, isAdmin: true });
            toast.success('Welcome, Moderator!');
          } else {
            throw new Error('Invalid admin credentials');
          }
        } catch (error) {
          console.error('Admin sign in error:', error);
          toast.error('Failed to sign in as admin');
          throw error;
        }
      },

      signOut: async () => {
        try {
          const { error } = await supabase.auth.signOut();
          if (error) throw error;
          
          set({ user: null, isAuthenticated: false, isAdmin: false });
          toast.success('Signed out successfully');
        } catch (error) {
          console.error('Sign out error:', error);
          toast.error('Failed to sign out');
        }
      }
    }),
    {
      name: 'auth-storage',
      version: 1
    }
  )
);
import React, { useState } from 'react';
import { X, Camera, MapPin, Phone, Mail, Facebook, Instagram, Globe } from 'lucide-react';
import toast from 'react-hot-toast';

interface ProfileEditModalProps {
  profile: any;
  onClose: () => void;
  onSave: (updatedProfile: any) => void;
}

const TikTokIcon = () => (
  <svg 
    className="w-4 h-4" 
    viewBox="0 0 24 24" 
    fill="currentColor"
  >
    <path d="M19.321 5.562a5.122 5.122 0 01-3.585-1.464A5.116 5.116 0 0114.27.513h-3.51v14.93c0 1.99-1.617 3.605-3.607 3.605a3.607 3.607 0 01-3.608-3.605c0-1.99 1.617-3.606 3.608-3.606.364 0 .715.054 1.046.154V8.348a7.207 7.207 0 00-1.046-.077C3.248 8.271 0 11.518 0 15.443 0 19.368 3.248 22.615 7.153 22.615c3.904 0 7.153-3.247 7.153-7.172V8.005a8.531 8.531 0 005.015 1.621V5.562z"/>
  </svg>
);

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ profile, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    name: profile.name || '',
    email: profile.email || '',
    phone: profile.phone || '',
    location: profile.location || '',
    bio: profile.bio || '',
    socialLinks: { ...profile.socialLinks }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (!formData.name.trim()) {
        toast.error('Name is required');
        return;
      }

      onSave(formData);
    } catch (error) {
      toast.error('Failed to update profile');
    }
  };

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        onSave({ ...formData, avatar_url: imageUrl });
        toast.success('Profile photo updated!');
      } catch (error) {
        toast.error('Failed to update profile photo');
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white w-full max-w-2xl rounded-xl shadow-xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Edit Profile</h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto">
          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Profile Photo */}
            <div className="flex items-center gap-4">
              <div className="relative">
                <img
                  src={profile.avatar_url}
                  alt={formData.name}
                  className="w-24 h-24 rounded-full object-cover"
                />
                <label className="absolute bottom-0 right-0 p-1 bg-primary-600 text-white rounded-full cursor-pointer">
                  <Camera className="w-4 h-4" />
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handlePhotoUpload}
                  />
                </label>
              </div>
              <div>
                <h3 className="font-medium">Profile Photo</h3>
                <p className="text-sm text-gray-500">
                  Upload a new profile photo
                </p>
              </div>
            </div>

            {/* Basic Info */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <Mail className="w-4 h-4" />
                    Email
                  </div>
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <Phone className="w-4 h-4" />
                    Phone
                  </div>
                </label>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                  placeholder="(123) 456-7890"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <MapPin className="w-4 h-4" />
                    Location
                  </div>
                </label>
                <input
                  type="text"
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                  placeholder="City, State"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Bio
                </label>
                <textarea
                  value={formData.bio}
                  onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
                  rows={3}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                  placeholder="Tell others about yourself"
                />
              </div>
            </div>

            {/* Social Media Links */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Social Media Links</h3>
              {[
                { id: 'facebook', name: 'Facebook', icon: Facebook },
                { id: 'instagram', name: 'Instagram', icon: Instagram },
                { id: 'tiktok', name: 'TikTok', icon: TikTokIcon },
                { id: 'website', name: 'Website', icon: Globe }
              ].map((platform) => (
                <div key={platform.id}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    <div className="flex items-center gap-2">
                      <platform.icon className="w-4 h-4" />
                      {platform.name}
                    </div>
                  </label>
                  <input
                    type="text"
                    value={formData.socialLinks[platform.id] || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      socialLinks: {
                        ...formData.socialLinks,
                        [platform.id]: e.target.value
                      }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                    placeholder={platform.id === 'website' ? 'https://' : '@username'}
                  />
                </div>
              ))}
            </div>
          </form>
        </div>

        {/* Fixed Footer */}
        <div className="border-t p-6 bg-white">
          <div className="flex gap-4 justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditModal;
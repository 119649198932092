import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import CategoryBar from './components/CategoryBar';
import PostCard from './components/PostCard';
import PostForm from './components/CreatePost/PostForm';
import PostDetail from './components/PostDetail';
import MessageCenter from './components/MessageCenter/MessageCenter';
import SavedItems from './components/SavedItems';
import ProfilePage from './pages/ProfilePage';
import SearchResults from './components/SearchResults';
import AdminDashboard from './components/Admin/AdminDashboard';
import { useListingStore } from './store/listingStore';
import { useAuthStore } from './store/authStore';
import MobileNav from './components/MobileNav';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import toast from 'react-hot-toast';

// Import category pages
import {
  VehiclesPage,
  FurniturePage,
  ElectronicsPage,
  ClothingPage,
  GamingPage,
  RealEstatePage,
  HomeGardenPage,
  SportsPage,
  ToolsPage,
  BabyKidsPage,
  MusicPage,
  WatchesPage,
  ShoesPage,
  CollectiblesPage
} from './pages/categories';

// Auth Guard HOC
const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuthStore();
  const location = useLocation();

  if (!isAuthenticated) {
    toast.error('Please sign in to access this page');
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const App = () => {
  const [showPostForm, setShowPostForm] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null);

  const { isAuthenticated } = useAuthStore();
  const { getAllListings, initializeListings } = useListingStore();

  // Initialize listings when the app loads
  useEffect(() => {
    initializeListings();
  }, [initializeListings]);

  const handleMessageClick = () => {
    if (!isAuthenticated) {
      toast.error('Please sign in to view messages');
      return;
    }
    setShowMessages(true);
  };

  const handlePostButtonClick = () => {
    if (!isAuthenticated) {
      toast.error('Please sign in to create a listing');
      return;
    }
    setShowPostForm(true);
  };

  const handleSearch = (query: string, results: any[]) => {
    setSearchQuery(query);
    setSearchResults(results);
    setSelectedCategory(null);
  };

  const handleHomeClick = () => {
    setSelectedCategory(null);
    setSearchQuery('');
    setSearchResults([]);
  };

  const handlePostClick = (post: any) => {
    setSelectedPost(post);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-50">
        {/* Fixed Header */}
        <div className="fixed top-0 left-0 right-0 z-50 bg-white shadow-sm">
          <Navbar
            onPostClick={handlePostButtonClick}
            onMessageClick={handleMessageClick}
            onHomeClick={handleHomeClick}
            onSearch={handleSearch}
          />
          <CategoryBar
            onHomeClick={handleHomeClick}
            selectedCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
          />
        </div>

        {/* Main Content Area - Adjusted for fixed header and bottom nav */}
        <main className="flex-1 container max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 pt-36 pb-24 md:pb-8">
          <Routes>
            {/* Home Route */}
            <Route
              path="/"
              element={
                searchQuery ? (
                  <SearchResults
                    query={searchQuery}
                    results={searchResults}
                    onClose={handleHomeClick}
                    onPostClick={handlePostClick}
                  />
                ) : (
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-4">
                    {getAllListings()
                      .filter(post => !selectedCategory || post.category === selectedCategory)
                      .map(post => (
                        <PostCard
                          key={post.id}
                          post={post}
                          onPostClick={() => handlePostClick(post)}
                          showHeart={true}
                        />
                      ))}
                  </div>
                )
              }
            />

            {/* Protected Routes */}
            <Route path="/saved-items" element={
              <PrivateRoute>
                <SavedItems />
              </PrivateRoute>
            } />
            <Route path="/profile/*" element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            } />
            <Route path="/admin/*" element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            } />
            
            {/* Category Routes */}
            <Route path="/categories/vehicles" element={<VehiclesPage />} />
            <Route path="/categories/furniture" element={<FurniturePage />} />
            <Route path="/categories/electronics" element={<ElectronicsPage />} />
            <Route path="/categories/clothing" element={<ClothingPage />} />
            <Route path="/categories/gaming" element={<GamingPage />} />
            <Route path="/categories/real-estate" element={<RealEstatePage />} />
            <Route path="/categories/home-garden" element={<HomeGardenPage />} />
            <Route path="/categories/sports" element={<SportsPage />} />
            <Route path="/categories/tools" element={<ToolsPage />} />
            <Route path="/categories/baby-kids" element={<BabyKidsPage />} />
            <Route path="/categories/music" element={<MusicPage />} />
            <Route path="/categories/watches" element={<WatchesPage />} />
            <Route path="/categories/shoes" element={<ShoesPage />} />
            <Route path="/categories/collectibles" element={<CollectiblesPage />} />

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        {/* Footer - Hidden on mobile */}
        <div className="hidden md:block">
          <Footer />
        </div>

        {/* Mobile Navigation - Only visible on mobile */}
        <div className="md:hidden">
          <MobileNav
            onPostClick={handlePostButtonClick}
            onMessageClick={handleMessageClick}
          />
        </div>

        {/* Modals */}
        {showPostForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto">
            <PostForm
              onClose={() => setShowPostForm(false)}
              onPostCreated={() => {
                setShowPostForm(false);
                toast.success('Post created successfully!');
              }}
            />
          </div>
        )}

        {showMessages && (
          <MessageCenter onClose={() => setShowMessages(false)} />
        )}

        {selectedPost && (
          <PostDetail
            post={selectedPost}
            onClose={() => setSelectedPost(null)}
          />
        )}
      </div>
    </Router>
  );
};

export default App;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, Home, Search, ArrowLeft } from 'lucide-react';
import Logo from './Logo';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <Logo variant="stack" size="md" />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex items-center justify-center p-4">
        <div className="max-w-md w-full text-center">
          {/* Error Icon */}
          <div className="mb-8 relative">
            <div className="absolute inset-0 bg-primary-100 rounded-full transform scale-150 animate-pulse" />
            <AlertCircle className="w-20 h-20 text-primary-600 mx-auto relative" />
          </div>

          {/* Error Message */}
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Page Not Found
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            The page you're looking for doesn't exist or has been moved.
          </p>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => navigate('/')}
              className="inline-flex items-center justify-center px-6 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
            >
              <Home className="w-5 h-5 mr-2" />
              Back to Home
            </button>
            <button
              onClick={() => navigate(-1)}
              className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Go Back
            </button>
          </div>

          {/* Search Box */}
          <div className="mt-12 bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Looking for something specific?
            </h2>
            <div className="relative">
              <input
                type="text"
                placeholder="Search our marketplace..."
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          {/* Quick Links */}
          <div className="mt-8">
            <h3 className="text-sm font-medium text-gray-700 mb-3">
              Popular Categories
            </h3>
            <div className="flex flex-wrap justify-center gap-2">
              {['Electronics', 'Furniture', 'Fashion', 'Cars', 'Real Estate'].map((category) => (
                <button
                  key={category}
                  onClick={() => navigate(`/categories/${category.toLowerCase()}`)}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-colors"
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="bg-white border-t py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm text-gray-500">
            © {new Date().getFullYear()} YnotPost. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
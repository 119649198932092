import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { Profile } from '../types/profile';

const STORAGE_PREFIX = 'ynotpost_';

class StorageService {
  private static instance: StorageService;
  private storage: Storage;

  private constructor() {
    this.storage = window.localStorage;
  }

  static getInstance(): StorageService {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  set<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      this.storage.setItem(`${STORAGE_PREFIX}${key}`, serializedValue);
      // Dispatch storage event for cross-tab sync
      window.dispatchEvent(new StorageEvent('storage', {
        key: `${STORAGE_PREFIX}${key}`,
        newValue: serializedValue
      }));
    } catch (error) {
      console.error('Storage error:', error);
      toast.error('Failed to save data');
    }
  }

  get<T>(key: string): T | null {
    try {
      const item = this.storage.getItem(`${STORAGE_PREFIX}${key}`);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error('Storage error:', error);
      return null;
    }
  }

  remove(key: string): void {
    try {
      this.storage.removeItem(`${STORAGE_PREFIX}${key}`);
      window.dispatchEvent(new StorageEvent('storage', {
        key: `${STORAGE_PREFIX}${key}`,
        newValue: null
      }));
    } catch (error) {
      console.error('Storage error:', error);
    }
  }

  clear(): void {
    try {
      Object.keys(this.storage)
        .filter(key => key.startsWith(STORAGE_PREFIX))
        .forEach(key => this.storage.removeItem(key));
      window.dispatchEvent(new StorageEvent('storage', {
        key: null,
        newValue: null
      }));
    } catch (error) {
      console.error('Storage error:', error);
    }
  }

  // Profile management
  getProfile(userId: string): Profile | null {
    return this.get<Profile>(`profile_${userId}`);
  }

  updateProfile(userId: string, updates: Partial<Profile>): Profile {
    const currentProfile = this.getProfile(userId);
    if (!currentProfile) {
      throw new Error('Profile not found');
    }
    
    const updatedProfile = { ...currentProfile, ...updates };
    this.set(`profile_${userId}`, updatedProfile);
    return updatedProfile;
  }

  createDefaultProfile(userId: string): Profile {
    const defaultProfile: Profile = {
      id: userId,
      name: 'New User',
      bio: 'Tell others about yourself',
      location: '',
      joinDate: new Date().toISOString(),
      verificationLevel: 'Basic',
      responseTime: '< 24 hours',
      completedDeals: 0,
      rating: 0,
      reviews: 0,
      socialLinks: {},
      badges: [],
      stats: {
        itemsSold: 0,
        itemsBought: 0,
        totalEarnings: 0,
        completionRate: 100,
        repeatCustomers: 0,
        averageResponseTime: 0
      }
    };
    this.set(`profile_${userId}`, defaultProfile);
    return defaultProfile;
  }

  // Cross-tab synchronization
  setupSync(): void {
    window.addEventListener('storage', (event) => {
      if (event.key?.startsWith(STORAGE_PREFIX)) {
        // Notify stores to rehydrate
        window.dispatchEvent(new CustomEvent('storageSync', {
          detail: {
            key: event.key.replace(STORAGE_PREFIX, ''),
            newValue: event.newValue ? JSON.parse(event.newValue) : null
          }
        }));
      }
    });
  }
}

export const storage = StorageService.getInstance();

// Initialize cross-tab sync
storage.setupSync();
// Service Worker registration and management
export const registerServiceWorker = async () => {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.register('/sw.js');
      return registration;
    }
  } catch (error) {
    // Silently handle service worker registration failure
    // This allows the app to work without service workers in unsupported environments
    console.debug('Service Worker registration skipped - environment may not support it');
  }
  return null;
};

export const unregisterServiceWorker = async () => {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
      return true;
    }
  } catch (error) {
    console.debug('Service Worker unregistration skipped');
  }
  return false;
};

export const checkServiceWorkerStatus = async () => {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      return {
        supported: true,
        active: !!registration.active,
        state: registration.active?.state || 'none'
      };
    }
  } catch (error) {
    // Service workers not supported or not available
  }
  return {
    supported: false,
    active: false,
    state: 'unsupported'
  };
};